<template>
  <div>
    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon> fas fa-key </v-icon>
        <v-toolbar-title class="pl-4">
          Configurações de chaves - Cotação e Conferência:
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="(dialogAddToken = true, limparItemSelecionado())"> Adicionar </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table
              dense
              :headers="grid.objetos.cabecalho"
              :items="grid.objetos.items"
              :items-per-page="grid.objetos.porPagina"
              hide-default-footer
              class="elevation-1"
              :loading="grid.objetos.carregando"
            >
              <template v-slot:item.operacoes="{ item }">
                <v-icon size="16" color="green" class="operacoes" @click="abrirModalEditarToken(item)">fas fa-pen</v-icon>
                <v-icon size="16" color="red" class="operacoes" @click="deleteToken(item)">fas fa-trash</v-icon>
              </template>
              <template v-slot:item.tipoToken="{ item }">
                <span v-if="item.tipo == 2">
                  Conferência
                </span>
                <span v-else>
                  Cotação
                </span>
              </template>
              <template v-slot:item.tipo="{ item }">
                <v-app-bar-nav-icon style="cursor: default;" v-if="item.tipo == 0 || item.tipo == 1 && item.tipoToken != 2">
                  <v-icon size="16" color="red" v-if="item.tipo == 0">fas fa-times</v-icon>
                  <v-icon size="16" color="green" v-else-if="item.tipo == 1">fas fa-check</v-icon>
                </v-app-bar-nav-icon>
                <span v-else>
                  Não se aplica
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim principal -->

    <!-- Inicio Modal Add Token -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogAddToken" max-width="800">
          <v-card>
            <v-card-title class="text-h5" style="justify-content:center"> Nova Chave </v-card-title>
            <v-card-title class="text-h7"> Token Nome </v-card-title>
            <v-card-text style="padding-bottom: 0">
              <v-text-field solo v-model="itemSelecionado.nomeOriginal"></v-text-field>
            </v-card-text>
            <v-card-title class="text-h7" style="padding-top: 0"> Token </v-card-title>
            <v-card-text style="padding-bottom: 0">
              <v-text-field solo v-model="itemSelecionado.valor"></v-text-field>
            </v-card-text>
            <v-card-title class="text-h7" style="padding-top: 0"> Tipo </v-card-title>
            <v-card-text style="padding-bottom: 0">
              <v-radio-group v-model="itemSelecionado.tipoToken" row>
                <v-radio label="Cotação" :value="3"></v-radio>
                <v-radio label="Conferência" :value="2"></v-radio>
              </v-radio-group>
            </v-card-text>
            <v-card-title class="text-h7" style="padding-top: 0"> Stop Embalamento </v-card-title>
            <v-card-text style="padding-bottom: 0">
              <v-checkbox
                v-model="chkBoxStopEmbalamento"
                label="Embalamento Manual"
                :disabled="itemSelecionado.tipoToken == 2"
              ></v-checkbox>
            </v-card-text>
            <v-card-actions class="alignmentDialog">
              <v-btn
                class="ma-2"
                color="error"
                @click="dialogAddToken = false, carregarTokens()"
              >
                Cancelar
              </v-btn>
              <v-btn
                class="ma-2"
                color="primary"
                @click="addToken()"
                :disabled="itemSelecionado.nomeOriginal == '' || itemSelecionado.valor == ''"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim Modal Add Token -->

      <!-- Modal Alteracao -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogEdit" max-width="800">
          <v-card>
            <v-card-title class="text-h5" style="justify-content:center"> Editar Chave </v-card-title>
            <v-card-title class="text-h7"> Token Nome </v-card-title>
            <v-card-text style="padding-bottom: 0">
              <v-text-field solo readonly v-model="itemSelecionado.nomeOriginal" disabled></v-text-field>
            </v-card-text>
            <v-card-title class="text-h7" style="padding-top: 0"> Token </v-card-title>
            <v-card-text style="padding-bottom: 0">
              <v-text-field solo v-model="itemSelecionado.valor"></v-text-field>
            </v-card-text>
            <v-card-title class="text-h7" style="padding-top: 0"> Tipo </v-card-title>
            <v-card-text style="padding-bottom: 0">
              <v-radio-group v-model="itemSelecionado.tipoToken" row>
                <v-radio label="Cotação" :value="3"></v-radio>
                <v-radio label="Conferência" :value="2"></v-radio>
              </v-radio-group>
            </v-card-text>
            <v-card-title class="text-h7" style="padding-top: 0"> Stop Embalamento </v-card-title>
            <v-card-text style="padding-bottom: 0">
              <v-checkbox
                v-model="chkBoxStopEmbalamento"
                label="Embalamento Manual"
                :disabled="itemSelecionado.tipoToken == 2"
              ></v-checkbox>
            </v-card-text>
            <v-card-actions class="alignmentDialog">
              <v-btn
                class="ma-2"
                color="error"
                @click="dialogEdit = false, carregarTokens()"
              >
                Cancelar
              </v-btn>
              <v-btn
                class="ma-2"
                color="primary"
                @click="editarToken()"
                :disabled="itemSelecionado.valor == ''"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim Modal Alteracao -->

      <!-- Inicio Modal Exclusao -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogDelete" max-width="400">
          <v-card>
            <div
              style="display: flex; justify-content: center; padding: 15px 0 0 0"
            >
              <v-icon x-large>fa-exclamation</v-icon>
            </div>
            <v-card-title class="text-h9 alignmentDialogDelete">
              Warning
            </v-card-title>
            <v-card-text class="alignmentDialogDelete">
              <strong>Deseja realmente excluir a chave?<br>
              Nome: {{ itemSelecionado.nomeOriginal }}<br>
              Token: {{ itemSelecionado.valor }}</strong></v-card-text
            >
            <v-card-actions class="alignmentDialog">
              <v-btn class="ma-2" color="primary" @click="dialogDelete = false">
                Cancelar
              </v-btn>
              <v-btn class="ma-2" color="error" @click="deleteToken()">
                Excluir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim Modal Exclusao -->

      <!-- Inicio Modal Sucesso ao excluir -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogSucessDelete" max-width="400">
          <v-card>
            <div
              style="display: flex; justify-content: center; padding: 15px 0 0 0"
            >
              <v-icon x-large>fa-check</v-icon>
            </div>
            <v-card-title class="text-h5 alignmentDialogDelete">
              Sucesso!
            </v-card-title>
            <v-card-text class="alignmentDialogDelete"
              ><strong>Token excluido com sucesso!!!</strong></v-card-text
            >
            <v-card-actions class="alignmentDialogDelete">
              <v-btn class="ma-2" color="success" @click="(dialogSucessDelete = false, carregarTokens())">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim Modal Sucesso ao excluir -->

      <!-- Inicio modal Error salvar informacoes-->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogError" max-width="400">
          <v-card>
            <div
              style="display: flex; justify-content: center; padding: 15px 0 0 0"
            >
              <v-icon x-large>fa-exclamation</v-icon>
            </div>
            <v-card-title class="text-h5 alignmentDialogDelete">
              Error
            </v-card-title>
            <v-card-text class="alignmentDialogDelete"
              >Erro ao salvar as informações. Tente novamente. Se o erro persistir, contacte um administrador.</v-card-text
            >
            <v-card-actions class="alignmentDialogDelete">
              <v-btn class="ma-2" color="error" @click="dialogError = false">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim modal Error salvar informacoes-->

       <!-- Inicio modal Sucesso ao editar -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogSucessSave" max-width="400">
          <v-card>
            <div
              style="display: flex; justify-content: center; padding: 15px 0 0 0"
            >
              <v-icon x-large>fa-check</v-icon>
            </div>
            <v-card-title class="text-h5 alignmentDialogDelete">
              Sucesso!
            </v-card-title>
            <v-card-text class="alignmentDialogDelete"
              >Configurações de token salva.</v-card-text
            >
            <v-card-actions class="alignmentDialogDelete">
              <v-btn class="ma-2" color="success" @click="(dialogSucessSave = false, carregarTokens())">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim modal Sucesso ao editar-->
  </div>
</template>

<script>
import { baseApiUrlPersys } from "@/global";
import axios from "axios";
import Template from "../components/Template.vue";


export default {
  components: {
    Template,
  },
  name: "Objetos",
  data: () => ({
    dialogSucessDelete: false,
    dialogAddToken: false,
    dialogEdit: false,
    dialogDelete: false,
    dialogSucessSave: false,
    dialogError: false,
    cod_empresa: '',
    itemSelecionado: {
      codigo: null,
      codigoEmpresa: null,
      nome: "",
      nomeOriginal: "",
      valor: "",
      tipo: 0,
      tipoToken: 3,
    },
    grid: {
      objetos: {
        cabecalho: [
          { text: "Nome", value: "nomeOriginal", sortable: false },
          { text: "Token", value: "valor", sortable: false },
          { text: "Tipo", value: "tipoToken", sortable: false },
          { text: "Embalamento Manual", value: "tipo", align: "center", sortable: false },
          { text: "", value: "operacoes", align: "end", sortable: false },
        ],
        items: [],
        carregando: false,
        porPagina: 10,
      },
    },
    chkBoxStopEmbalamento: false,
  }),
  created() {
    this.cod_empresa = Number(this.$store.state.cod_empresa);
  },
  mounted() {
    this.carregarTokens();
  },
  methods: {

    async carregarTokens() {
      this.grid.objetos.items = [];
      this.grid.objetos.carregando = true;
      await axios
        .get(`${baseApiUrlPersys}/token`,  {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          if(res.data == ''){
            this.grid.objetos.items = [];
            this.grid.objetos.porPagina = 1000;
          }else{
            this.grid.objetos.items = res.data;
            this.grid.objetos.porPagina = 1000;
            let arrTokenWithTypeTokenOne = [];
            res.data.forEach(item => {
              if(item.tipoToken == 2 || item.tipoToken == 3) {
                arrTokenWithTypeTokenOne.push(item);
              }
              this.grid.objetos.items = arrTokenWithTypeTokenOne;
            });
          }
          this.grid.objetos.carregando = false
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
          this.grid.objetos.carregando = false
        })
    },

    async editarToken() {
      if(this.chkBoxStopEmbalamento && this.itemSelecionado.tipoToken == 3) {
        this.itemSelecionado.tipo = 1;
      } else if(!this.chkBoxStopEmbalamento && this.itemSelecionado.tipoToken == 3) {
        this.itemSelecionado.tipo = 0;
      } else {
        this.itemSelecionado.tipo = 2;
      }

      await axios
        .put(`${baseApiUrlPersys}/token/codigo/${this.itemSelecionado.codigo}`, {
          nome: this.itemSelecionado.nomeOriginal,
          valor: this.itemSelecionado.valor,
          tipo: this.itemSelecionado.tipo,
          tipoToken: this.itemSelecionado.tipoToken,
        }, {headers: {'Token-API': this.$store.state.token_persys}})
        .then((response) => {
          this.dialogEdit = false;
          this.limparItemSelecionado();
          this.carregarTokens();
          this.$swal({
            title: 'Sucesso!!!',
            text: 'Token editado com sucesso.',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.dialogError = true;
        });
    },

    async addToken() {
      if(this.chkBoxStopEmbalamento && this.itemSelecionado.tipoToken == 3) {
        this.itemSelecionado.tipo = 1;
      } else if(!this.chkBoxStopEmbalamento && this.itemSelecionado.tipoToken == 3) {
        this.itemSelecionado.tipo = 0;
      } else {
        this.itemSelecionado.tipo = 2;
      }

      let hasError = false;
      this.grid.objetos.items.forEach(item => {
        if(item.nomeOriginal.toUpperCase() == this.itemSelecionado.nomeOriginal.toUpperCase()) {
          this.$swal({
            title: 'Alerta',
            text: 'Token Nome informado já consta no sistema !',
            icon: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: '#62C6A2',
            allowOutsideClick: false,
          });
          hasError = true;
          return;
        }

        if(item.nome.toUpperCase() == this.itemSelecionado.nomeOriginal.toUpperCase()) {
          this.$swal({
            title: 'Alerta',
            text: 'Token Nome informado já consta no sistema !',
            icon: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: '#62C6A2',
            allowOutsideClick: false,
          });
          hasError = true;
          return;
        }
      });

      if(!hasError) {
        await axios
          .post(`${baseApiUrlPersys}/token`, {
            nome: this.itemSelecionado.nomeOriginal,
            valor: this.itemSelecionado.valor,
            codigoEmpresa: this.cod_empresa,
            tipo: this.itemSelecionado.tipo,
            tipoToken: this.itemSelecionado.tipoToken,
          }, {headers: {'Token-API': this.$store.state.token_persys}})
          .then((response) => {
            this.dialogAddToken = false;
            this.limparItemSelecionado();
            this.carregarTokens();
            this.$swal({
              title: 'Sucesso!!!',
              text: 'Token adicionado com sucesso.',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
          })
          .catch((error) => {
            this.dialogError = true;
          });
      }
    },

    async deleteToken (item) {
      this.tipoEmbalamento = ""
      if(item.tipo == 0 || item.tipo == null){
        this.tipoEmbalamento = "Não"
      }else if(item.tipo == 1) {
        this.tipoEmbalamento = "Sim"
      } else {
        this.tipoEmbalamento = "Não se aplica"
      }

      this.$swal({
        title: 'Confirmação',
        html: `Deseja realmente excluir a chave? <br> Nome: ${ item.nomeOriginal }<br> Token: ${ item.valor }<br> Embalamento Manual: ${this.tipoEmbalamento}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios
            .delete(`${baseApiUrlPersys}/token/codigo/${item.codigo}`, {headers: {'Token-API': this.$store.state.token_persys}})
            .then((res) => {
              this.$swal({
              title: 'Sucesso!!!',
              text: 'Token excluído com sucesso.',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
              this.carregarTokens();
            });
        }
      });
    },

    limparItemSelecionado(){
      this.itemSelecionado.nomeOriginal = "";
      this.itemSelecionado.nome = "";
      this.itemSelecionado.valor = "";
      this.itemSelecionado.tipo = 0;
      this.itemSelecionado.tipoToken = 3;
      this.chkBoxStopEmbalamento = false;
    },

    abrirModalEditarToken(item) {
      if(item.tipo == 1) {
        this.chkBoxStopEmbalamento = true;
      } else {
        this.chkBoxStopEmbalamento = false;
      }
      this.dialogEdit = true;
      this.itemSelecionado = item
    },

    abrirModalApagarToken(item){
      this.dialogDelete = true;
      this.itemSelecionado = item
    },

    fechar() {
      this.$router.push("/");
    },
  },
  watch: {
    'itemSelecionado.tipoToken' : function (val, oldVal) {
      if (val == 0 || val == 2) {
        this.chkBoxStopEmbalamento = false;
      }
    },
  },
};
</script>
<style scoped>
.operacoes {
  cursor: pointer;
  justify-content: flex-end;
  padding-right: 8px;
}
.operacoes::before{
  background-color: transparent !important;
}

.operacoes::after{
  background-color: transparent !important;
}

.alignmentDialog, .btnOperacoes {
  display: flex;
  justify-content: flex-end
}
.alignmentDialogDelete{
  display: flex;
  justify-content: center;
}

.v-icon.v-icon::after{
  display: none;
}

</style>
